import moment from 'moment';
import axios from 'axios';

export const GET_DATA = 'GET_DATA';
export const UPDATE_FILTER = 'UPDATE_FILTER';

function filterData(dataToFilter) {
  const now = moment();
  const filteredData = dataToFilter.filter((obj) => {
    if(moment(obj.date).isSameOrAfter(now)) {
      return true;
    }
    return false;
  });
  return filteredData;
}

export function getDataSuccess(response) {
  response.data.data = filterData(response.data.data);
  response.data.freshDate = moment(response.data.freshDate, "YYYY-MM-DD HH:mm:ss.SSS");  
  return {
    type: GET_DATA,
    payload: response.data
  }
}

export function getData() {
  const url = `${process.env.REACT_APP_S3_DOMAIN}/data.json`;
  return dispatch =>
    axios.get(url).then(response =>
      dispatch(getDataSuccess(response)));
}

export function updateFilter(filter, keys, updateValue) {
  return {
    type: UPDATE_FILTER,
    payload: {
      "filter": filter,
      "keys": keys,
      "updateValue": updateValue
    }
  };
}
