import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../styles";
import TorresAppBar from "../../torres_app_bar/components/torres_app_bar";
import TorresFooter from "../../torres_footer/components/torres_footer";

class LandingPageOff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      submitted: false
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.submitEmail = this.submitEmail.bind(this);
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }
  submitEmail() {
    const email = encodeURI(this.state.email);
    const url = `https://hooks.zapier.com/hooks/catch/4475817/n7zwlm/?email=${email}`;
    axios.get(url).then(response => {
      this.setState({ submitted: true });
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <TorresAppBar visible={false} />
        <Grid container>
          <Grid item className={classes.landingGridBackground}>
            <Grid container className={classes.grid}>
              <Grid item xs={12} className={classes.landingText}>
                <Typography variant="h4">
                  Unfortunately Vertice Patagonia has added a captcha to their site so I'm no longer able to scrape the data.
                  You'll have to go to the 3 travel sites directly. 
                  <a href="https://www.adventurealan.com/torres-del-paine-w-trek-and-circuit-trek-quick-and-easy-guide-to-essential-trip-planning/">Adventure Alan</a> and <a href="http://outofofficemode.com/planning-the-w-trek-backpacking-torres-del-paine/">Out Of Office Mode</a> have good write ups on how to plan.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.imageCredit} variant="subtitle2">
              <a
                className={classes.imageCredit}
                href="https://flic.kr/p/oD2YJV"
              >
                Image © Christopher Michel
              </a>
            </Typography>
          </Grid>
        </Grid>
        <TorresFooter />
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(LandingPageOff));
