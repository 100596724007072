import update from 'immutability-helper';

import { GET_PLANS, GET_PLANS_PENDING, GET_DROP_DOWN_DATA } from '../actions/action_trip_plans'


const INITIAL_STATE = {
    tripPlans: [],
    tripPlansPending: false,
    dropDownData: null
}

export default function (state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_PLANS:
            const nextState = update(state, {
                tripPlans: {$set:action.payload},
                tripPlansPending: {$set:false}
            });
            return nextState;
        case GET_PLANS_PENDING:            
            const nextStatePending = update(state, {
                tripPlansPending: {$set:true}
            });
            return nextStatePending;
        case GET_DROP_DOWN_DATA:
            const nextStateDropDown = update(state, {
                dropDownData: {$set:action.payload}
            });
            return nextStateDropDown;
        default:
            return state;
    }
}
