import axios from 'axios';

export const GET_PLANS = 'GET_PLANS';
export const GET_PLANS_PENDING = 'GET_PLANS_PENDING';
export const GET_DROP_DOWN_DATA = 'GET_DROP_DOWN_DATA';

export function getPlansSuccess(response) {    
    return {
        type: GET_PLANS,
        payload: response.data
    }
}

export function getPlansPending() {  
    return {
        type: GET_PLANS_PENDING,
        payload: true
    }
}

export function getPlans(data) {
    const url = 'https://dt9yd0qdr7.execute-api.us-west-2.amazonaws.com/dev'
    
    return dispatch => {
        dispatch(getPlansPending());
        axios.post(url, data).then(response =>
            dispatch(getPlansSuccess(response))).catch(
                e=>console.log(e)
            );
        }
}

export function getDropDownDataSuccess(response) {
    return {
        type: GET_DROP_DOWN_DATA,
        payload: response.data.dropDownData
    }
}

export function getDropDownData() {
    const url = `${process.env.REACT_APP_S3_DOMAIN}/options_page_data.json`;
    return dispatch =>
      axios.get(url).then(response =>
        dispatch(getDropDownDataSuccess(response))).catch(
            e=>console.log(e)
        );
}
