import update from 'immutability-helper';
import moment from 'moment';

import { GET_DATA, UPDATE_FILTER } from '../actions/action_availability'
const INITIAL_STATE = {
  availability: [],
  filters: {companies_checked: {}, locations: {}},
  freshDate: moment({year: 1970})
};

function generateParameters(keys, updateValue) {
  if (keys.length === 1) {
    return {[keys[0]]:{$set:updateValue}};
  }
  else {
    const lenToSplice = keys.length - 1;
    return {[keys[0]] :generateParameters(keys.splice(1, lenToSplice), updateValue) };
  }
}

export default function (state = INITIAL_STATE, action) {
  switch(action.type) {
    case GET_DATA:    
      const nextState = update(state, {
        filters: {$set:action.payload.filter},
        availability: {$set:action.payload.data},
        freshDate: {$set:action.payload.freshDate}
      });
      return nextState;
    case UPDATE_FILTER:
      const params = generateParameters(action.payload.keys, action.payload.updateValue);
      const fullParams = { filters: {[action.payload.filter] : params} };
      const updateFilterState = update(state, fullParams);
      return updateFilterState;
    default:
      return state;
  }
}
