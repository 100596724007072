import { combineReducers } from "redux";
import AvailabilityPageReducer from './availability_page/reducers/reducer_availability_page';
import TripPlannerPageReducer from './trip_planner_page/reducers/reducer_trip_planner_page';

const rootReducer = combineReducers( {
  availabilityPage: AvailabilityPageReducer,
  tripPlannerPage: TripPlannerPageReducer  
});

export default rootReducer;
