import torres_img from "./static/torres.jpg";

export const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  grid: {
    padding: theme.spacing.unit * 2
  },
  submitGrid: {
    justify: "center"
  },
  availabilityGrid: {
    flexGrow: 1,
    padding: 4
  },
  dateGrid: {
    backgroundColor: theme.palette.secondary.main
  },
  cardGrid : {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit
  },
  hidden: {
    display: 'none'
  },
  /* search page */
  searchGrid : {
    backgroundColor: theme.palette.primary.light
  },
  searchExpansionPannelSummary : {
    padding: "18px"
  },
  searchExpansionPanelDetails : {
    display: "block"
  },
  chip: {
    margin: theme.spacing.unit / 2
  },
  form: {
    width: "100%"
  },
  button: {
    margin: theme.spacing.unit
  },
  searchButton: {
    margin: `auto auto auto auto`,
    display: "block"
  },
  /* Filter */
  expansionPanel: {
    "&:first-child": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      boxShadow: "none"
    },
    "&:last-child": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      boxShadow: "none"
    },
    backgroundColor: theme.palette.primary.light
  },
  /* Footer */
  footer: {
    backgroundColor: theme.palette.background.paper,    
    padding: `${theme.spacing.unit}px 0`
  },
  /* Welcome Root */
  welcomePaper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  /* Landing page */
  landingGridBackground: {
    // https://stackoverflow.com/questions/17134929/overlay-a-background-image-with-an-rgba-background-color
    background: [
      "linear-gradient(to bottom, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35))",
      "url(" + torres_img + ")"
    ],
    // eslint-disable-next-line
    background: [
      "-webkit-linear-gradient(top, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35))",
      "url(" + torres_img + ")"
    ],
    backgroundSize: "cover",
    [theme.breakpoints.down('sm')]: {
      minHeight: "485px",
    },
    [theme.breakpoints.up('md')]: {
      minHeight: "700px",
    },
    
    width: "100%"
  },
  landingText: {
    color: theme.palette.text.primary,
    textAlign: "center"
  },
  imageCredit: {
    textAlign: "right",
    textDecoration: 'none',
    color: theme.palette.text.primary, 
    "&:visited": { color: theme.palette.text.primary }
  },
  appBar: {
    position: 'static',
    boxShadow: 'none',
    boxSizing: 'border-box'
  },
  menuIconStyle: { margin: theme.spacing.unit, "&:hover": { color: theme.palette.primary.dark } },
  menuLink: { textDecoration: 'none', color: theme.palette.text.primary, "&:visited": { color: theme.palette.text.primary } }
});
