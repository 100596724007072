import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import ReactGA from 'react-ga';
import withTracker from './with_tracker';
import LandingPageOff from './landing_page_off/components/landing_page_off';
import {MuiThemeProvider,createMuiTheme} from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'
import full_theme from './theme_2'
import * as serviceWorker from './serviceWorker';
import reducers from './root_reducer';

import { MuiPickersUtilsProvider } from 'material-ui-pickers';
// pick utils
import MomentUtils from '@date-io/moment';

const full_theme_e = createMuiTheme(full_theme);

ReactGA.initialize('UA-125150546-2');
ReactGA.set({'appName': 'Torres', 'appVersion': process.env.REACT_APP_VERSION});

const createStoreWithMiddleware = applyMiddleware(
  thunk
)(createStore);

const store = createStoreWithMiddleware(reducers);

const content = (
  <Router>
    <Provider store={store}>
      <MuiThemeProvider theme={full_theme_e}>
        <MuiPickersUtilsProvider utils={MomentUtils}>          
          <Route exact path="/" component={withTracker(LandingPageOff)}/>          
 
        </MuiPickersUtilsProvider>      
      </MuiThemeProvider>
    </Provider>
  </Router>
);

ReactDOM.render(content, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
