import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import classNames from 'classnames';
import { AppBar, Menu, MenuItem, Toolbar, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { styles } from "../../styles";
import { navigateButtonWrapper } from "../../analytics";

class TorresAppBar extends Component {
  constructor(props){
    super(props);
    this.state = {
      anchorEl: null
    }
    this.handleMenuToggleClick = this.handleMenuToggleClick.bind(this);    
    this.handleClose = this.handleClose.bind(this);    
  }
  handleMenuToggleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }  
  handleClose() {
    this.setState({ anchorEl: null });
  }  
  render() {
    const { classes, visible } = this.props;
    const { anchorEl } = this.state;    
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <MenuIcon
            className={classNames(classes.menuIconStyle, ( visible ? null : classes.hidden ))}
            onClick={this.handleMenuToggleClick}
            aria-owns={anchorEl ? "menu" : undefined}
            aria-haspopup="true"
          />
          <Menu
            id="menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}            
          >
            <MenuItem 
              onClick={() => navigateButtonWrapper("Menu", "Home", this.props.history, "/")}              
            >
              Home
            </MenuItem>
            <MenuItem 
              onClick={() => navigateButtonWrapper("Menu", "Browse", this.props.history, "/browse_availability")}              
            >
              Browse
            </MenuItem>
            <MenuItem 
              onClick={() => navigateButtonWrapper("Menu", "Search", this.props.history, "/trip_planner")}                            
            >
              Search
            </MenuItem>
          </Menu>
          <Typography variant="h6" color="inherit" noWrap>
            Torres App
          </Typography>
        </Toolbar>
      </AppBar>
    );
  }
}
export default withStyles(styles)(withRouter(TorresAppBar));
